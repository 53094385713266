.sc-cancel-button {
  display: none;
  margin-left: 20px;
  border: none;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
}

.sc-cancel-button.sc-remove-button {
  display: inline-block;
}

.sc-modal-search {
  .andes-modal__overlay {
    top: 64px;
  }

  .andes-modal {
    display: none;
  }
}

.search-form .andes-form-control--search-box {
  display: flex;
  align-items: center;
}

.search-form .andes-form-control__field {
  height: unset;
  font-size: $font-size-14;
}
