// sass-lint:disable no-qualifying-elements
.sc-fallback {
  &-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  &-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.sc-fallback-filter {
  margin: 126px auto;

  &-img {
    width: 154px;
    height: 141px;
  }
}
