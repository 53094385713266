// sass-lint:disable no-misspelled-properties no-transition-all
.header-new .menu {
  margin: auto 0;
  width: 21px;
  height: 14px;

  .menu-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: $andes-white;
    box-shadow: 0 -4px 16px $box-shadow;
    padding: 16px;
    width: 100%;

    .login-btn {
      border-radius: 20px;
      padding: 17px 30px;
    }

    .user-icon-text {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #38bb9c;

      p {
        font-size: $font-size-18;
        line-height: 35px;
        font-weight: 700;
        color: $andes-white;
      }
    }

    .user-container-icon {
      display: flex;

      &.borderImage {
        border: 1.2px solid $andes-gray-100;
      }

      > svg {
        width: 48px;
        height: 48px;
      }
    }

    .search-form:first-of-type {
      display: flex;
      width: 100%;
    }

    .andes-form-control--search-box {
      width: 100%;
      border-radius: 0;
      align-items: center;
      flex-direction: row-reverse;

      .andes-form-control__close-icon {
        display: none;
      }

      label {
        display: block;
      }

      .andes-form-control__search-icon {
        margin-top: 0;
      }
    }

    .andes-form-control--search-box:hover {
      box-shadow: none;
    }
  }

  .menu-container.login {
    justify-content: flex-start;
  }

  .hamburger-btn {
    position: relative;
    width: 21px;
    height: 16px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
  }

  .hamburger-top-bread,
  .hamburger-patty,
  .hamburger-bottom-bread {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $andes-black;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .hamburger-top-bread {
    margin-top: 0;
    transform-origin: left center;
  }

  .hamburger-patty {
    margin-top: 6px;
    transform-origin: left center;
  }

  .hamburger-bottom-bread {
    margin-top: 12px;
    transform-origin: left center;
  }

  .hamburger-btn.open .hamburger-top-bread {
    top: -2px;
    left: 3px;
    transform: rotate(45deg);
  }

  .hamburger-btn.open .hamburger-patty {
    width: 0%;
    opacity: 0;
  }

  .hamburger-btn.open .hamburger-bottom-bread {
    top: 1px;
    left: 3px;
    transform: rotate(-45deg);
  }

  .menu-item {
    position: relative;
    display: flex;
    margin: auto;
    font-weight: 600;
    color: $andes-black;
    padding-right: 1.25em;
  }

  .nav-container-menu {
    position: absolute;
    right: 0;
    top: 0.15em;
    margin-top: 0.1em;
  }

  & > .menu-container {
    display: none;
  }
}

.header-new .sc-menu-mobile {
  position: fixed;
  top: 64px;
  left: 0;
  right: inherit;
  bottom: 0;

  &-content {
    width: 300px;
    background-color: $andes-white;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
    box-shadow: inset 0 4px 16px $andes-gray-100;

    .sc-navigation-mobile {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;

      &-header {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
      }

      &-header > a {
        padding-block: 30px;
        font-size: $font-size-14;
      }

      &-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 30px;
        padding: 0 24px;
        border-top: 1px solid $andes-gray-070-solid;
      }

      &-body > span {
        font-size: $font-size-12;
        color: $andes-gray-550;
        padding-top: 20px;
      }

      &-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 100%;
      }
    }
  }

  .sc-user-profile {
    display: flex;
    gap: 16px;
    align-items: center;

    .sc-data-profile {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}

@media (width <=374px) {
  .header-new .sc-modal-mobile-content {
    width: 266px;
  }
}

.header .menu {
  margin: auto 0;
  width: 21px;
  height: 14px;

  .menu-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 1em;
    background: $andes-white;
    box-shadow: 0 1px 2px $box-shadow;
    position: absolute;
    top: 3em;
    left: 0;
    padding: 1.25em;
    width: 100%;

    .login-btn {
      padding: 0;
      margin-left: 0.2em;
      color: $andes-gray-900-solid;
    }

    .user-icon-text {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 7em;

      p {
        font-size: $font-size-18;
        font-weight: 700;
      }
    }

    .user-container-icon {
      display: flex;

      > svg {
        width: 48px;
        height: 48px;
      }
    }

    .search-form:first-of-type {
      display: flex;
      width: 100%;
    }

    .andes-form-control--search-box {
      width: 100%;
      border-radius: 0;
      align-items: center;
      flex-direction: row-reverse;

      .andes-form-control__close-icon {
        display: none;
      }

      label {
        display: block;
      }

      .andes-form-control__search-icon {
        margin-top: 0;
      }
    }

    .andes-form-control--search-box:hover {
      box-shadow: none;
    }
  }

  .hamburger-btn {
    position: relative;
    width: 21px;
    height: 16px;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
  }

  .sc-menu-mobile {
    display: none;
  }

  .hamburger-top-bread,
  .hamburger-patty,
  .hamburger-bottom-bread {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $andes-black;
    transform: rotate(0deg);
    transition: 0.25s ease-in-out;
  }

  .hamburger-top-bread {
    margin-top: 0;
    transform-origin: left center;
  }

  .hamburger-patty {
    margin-top: 6px;
    transform-origin: left center;
  }

  .hamburger-bottom-bread {
    margin-top: 12px;
    transform-origin: left center;
  }

  .hamburger-btn.open .hamburger-top-bread {
    top: -2px;
    left: 3px;
    transform: rotate(45deg);
  }

  .hamburger-btn.open .hamburger-patty {
    width: 0%;
    opacity: 0;
  }

  .hamburger-btn.open .hamburger-bottom-bread {
    top: 1px;
    left: 3px;
    transform: rotate(-45deg);
  }

  .menu-item {
    position: relative;
    display: flex;
    margin: auto;
    font-weight: 600;
    color: $andes-black;
    padding-right: 1.25em;
  }

  .nav-container-menu {
    position: absolute;
    right: 0;
    top: 0.15em;
    margin-top: 0.1em;
  }

  .mobile-only.mobile-only {
    display: flex;
  }

  .user-photo__wrapper {
    align-items: center;
    border-radius: 50%;
    border: 2px solid #ffe91e;
    border-bottom: none;
    border-left: none !important;
    display: flex;
    height: 68px;
    justify-content: center;
    margin: 10px 22px 10px 14px;
    position: relative;
    width: 68px;

    .user-photo {
      height: 60px;
      width: 60px;
      z-index: 1;
      border-radius: 50%;
    }
  }

  .user-photo__wrapper::after {
    align-items: center;
    background-color: #3383fa;
    border-radius: 100%;
    bottom: -3px;
    color: #fff;
    content: '</>';
    display: flex;
    height: 28px;
    justify-content: center;
    position: absolute;
    right: -8px;
    width: 28px;
    z-index: 2;
  }

  .user-photo__message {
    font-size: 18px;
    font-weight: 700;
    align-self: center;
  }
}

.sc-menu-modal-content {
  width: 100%;
  height: 100%;
}

@media (min-width: $mobile-s) {
  .header-container-new .search-form {
    width: 30%;

    & > div {
      width: 100%;
    }
  }
}

@media (max-width: $mobile-s) {
  .header-new.header-mobile
    .nav-container.nav-mobile-container
    .sc-menu-mobile {
    top: 64px;
  }

  .header-new .header-container-new .search-form {
    width: 65% !important;

    & > div {
      width: 100%;
    }
  }
}
