// sass-lint:disable class-name-format
@media (width >= 1300px) {
  .presentation-image {
    border-bottom-right-radius: 2rem;
  }
}

.presentation-image {
  height: 360px;
  width: 100%;
  border-bottom-left-radius: 2rem;
  object-fit: cover;
}

.right-content-container {
  grid-column: 3 / 4;
}

.sc-html-content {
  grid-column: 2 / 3;
  font-size: 1rem;
  padding-top: 1.7em;
  padding-left: 4.75em;
  padding-right: 3em;

  li {
    margin-bottom: 10px;
  }

  h2 {
    font-size: 1.75em;
  }

  h2 strong,
  h3 strong {
    font-size: $font-size-28;
    font-weight: 600;
  }

  .text-container,
  .phrase-text-container .phrase-text-bloclquote {
    h1 {
      font-size: 2.2rem;
    }

    h2 {
      font-size: 2rem;
      margin-bottom: 0;
    }

    h3 {
      font-size: 1.75rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    h5 {
      font-size: 1.25rem;
    }

    h6 {
      font-size: 1rem;
    }

    ol {
      padding-left: 3em;
    }

    ul {
      padding-left: 3em;
    }

    p {
      margin-bottom: 1em;
    }

    a {
      color: $andes-blue-500;
    }
  }

  .image {
    &-container_one__xlarge {
      padding: 1em 0;
      width: 100%;
    }

    &__positioned {
      > img {
        width: 100%;
      }
    }
  }

  .text {
    &image {
      &__positioned_left {
        margin: 2em 0;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        column-gap: 1em;
      }

      &__positioned_right {
        margin: 2em 0;
        display: flex;
        align-items: center;
        column-gap: 1em;
      }

      &-image-container_left__small,
      &-image-container_right__small {
        width: 7.5em;
      }
    }

    &__positioned {
      margin: 2em 0;
    }
  }

  .html__positioned {
    width: 100%;

    > iframe {
      width: 100%;
      margin: 40px 0;
    }
  }

  .cta__positioned,
  .button__positioned {
    display: flex;
    box-shadow: 0 1px 2px $box-shadow;
    border-radius: 20px;
    border: 1px solid $andes-gray-070-solid;
    justify-content: center;
    padding: 2.5em 1em;
    margin-bottom: 0.5em;

    .cta-container_one,
    .cta-container_two,
    .button__container_two {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .cta-title {
        font-style: normal;
        font-size: 18px;
        line-height: 22px;
        font-weight: 600;
        margin: 0;
        text-align: center;
      }

      .cta-button,
      .cta-button-main,
      .btn-button_main {
        border: 0;
        background: none;
        display: flex;
        align-items: center;

        a {
          background-color: $andes-blue-500;
          border: none;
          border-radius: 22px;
          margin-top: 1em;
          min-height: 3em;
          padding: 1em 1.5em;
          color: $andes-white;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 15px;
        }

        a:active {
          border-color: transparent;
          background-color: $andes-blue-700;
          color: $andes-white;
        }
      }

      .cta-button-secondary,
      .btn-button_secondary {
        background-color: transparent;
        border-color: transparent;
        border-radius: 6px;
        margin-top: 1em;
        min-height: 3em;
        padding: 1em 1.5em 0;
        width: 100%;
      }
    }
  }
}

.sc-html-content::before {
  content: ' ';
  position: absolute;
  top: 1.7em;
  left: 4.75em;
  right: 3em;
  border-top: 1px solid $andes-gray-100-solid;
}
