// sass-lint:disable no-color-literals class-name-format clean-import-paths
$andes-theme: 'mercadolibre';

//andes components
@import '~@andes/typography/index';
@import '~@andes/dropdown/base';
@import '~@andes/button/index';
@import '~@andes/card/index';
@import '~@andes/badge/index';
@import '~@andes/thumbnail/index';
@import '~@andes/radio-list/index';
@import '~@andes/list/index';
@import '~@andes/form/index';
@import '~@andes/textfield/index';
@import '~@andes/tag/index';
@import '~@andes/spinner/index';
@import '~@andes/checkbox-list/index';
@import '~@andes/tabs/index';
@import '~seller-journey-ui/src/components/seller-coach';
@import '~seller-journey-ui/src/components/contextual-coach';
@import '~@andes/form/base';
@import '~@andes/search-box/index';
@import '~@andes/modal/index';
@import '~@andes/pagination/index';
@import '~@andes/tooltip/index';

// andes variables
@import './variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Proxima Nova';
  font-size: $font-size-base;
  text-decoration: none;
}

html {
  padding-right: 0 !important;
}

body {
  position: relative;
}

main {
  padding-top: 3.5em;
}

.first-steps-modal-image__image img {
  width: 174px !important;
  height: 126px !important;
  display: block;
  margin: 0 auto;
}

.tagsCategories {
  margin: 20px 0;

  .andes-tag {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.group-list-tag_38BB9C,
.group-list-tag_38BB9C:hover {
  background-color: #38bb9c;
  border: 0;
}

.group-list-tag_FFE600,
.group-list-tag_gs,
.group-list-tag_ss,
.group-list-tag_gp,
.group-list-tag_ad {
  background-color: #ffe600;
}

.group-list-tag_FFE600:hover,
.group-list-tag_gs:hover,
.group-list-tag_ss:hover,
.group-list-tag_gp:hover,
.group-list-tag_ad:hover {
  background-color: #ffe600;
  border: 1px solid #ffe600;
}

.group-list-tag_56CBF5,
.group-list-tag_56CBF5:hover {
  background-color: #56cbf5;
  border: 0;
}

.group-list-tag_A9DB1C,
.group-list-tag_A9DB1C:hover {
  background-color: #a9db1c;
  border: 0;
}

.group-list-tag_AB86FF,
.group-list-tag_AB86FF:hover {
  background-color: #ab86ff;
  border: 0;
}

.group-list-tag_FF52A8,
.group-list-tag_FF52A8:hover {
  background-color: #ff52a8;
  border: 0;
}

.group-list-tag_3483FA,
.group-list-tag_3483FA:hover {
  background-color: #3483fa;
  color: white;
  border: 0;
}

.seller-coach-module-title--desktop {
  margin-bottom: 45px;
}

@media (width <= 1199px) {
  main {
    padding-top: 3em;
  }
}

// Andes modifications

.andes-form-control--search-box--size-small .andes-form-control__field {
  height: auto;
}

// Custom Layout

@import '../containers/Layout';
@import '../components/Modal';
