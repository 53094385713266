@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

.icon-text {
  display: flex;
  justify-content: center;
  align-items: center;
}
