// sass-lint:disable no-misspelled-properties no-qualifying-elements
.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px 8px;
  border-radius: 3px;
  height: 18px;
  width: fit-content;

  .icon-custom {
    background-color: transparent !important;
  }

  > p {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
  }
}

.tag.solid {
  color: $andes-blue-500;
  background-color: $andes-blue-150;
}

.tag.solid.success {
  display: flex;
  align-items: center;
  color: $andes-green-500;
  background-color: $andes-green-100;

  .icon-custom {
    display: flex;
  }
}

.tag.outline {
  color: $andes-blue-500;
  border: 1px solid $andes-blue-500;
}

.tag.success {
  gap: 4px;
}

.tag.outline.success {
  display: flex;
  align-items: center;
  color: $andes-green-500;
  border: 1px solid $andes-green-500;

  .icon-custom {
    display: flex;
  }
}
