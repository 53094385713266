// sass-lint:disable class-name-format
.note-html-login {
  align-self: center;
}

.container-Warranty30 {
  min-height: 72px;
  margin: 0 1.25em 1.5em;
}

.container-Rocket40 {
  height: 64px;
  margin: 0 1.25em 1em;
}
