.sc-card-content {
  height: 290px;
  width: 280px;

  &-container.andes-card__content {
    padding: 16px;
    height: 290px;
    width: 280px;
  }

  &-image {
    width: 280px;
    bottom: 16px;
  }

  &-image-container {
    width: 280px;
  }

  &-image-crop-secondary {
    width: 280px;
    bottom: 16px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    max-width: 100%;
  }

  &-title-subtitle-container {
    padding: 0 24px;
  }
}
