.sc-reco-container {
  width: 100%;
  margin-bottom: $andes-spacing-56;

  &-title {
    margin: 10px 0;
    font-size: $font-size-24;
    font-weight: $font-weight-semibold;
  }

  .cta-button.outline {
    width: initial;
    padding: 0 16px 0 20px;

    .second-icon {
      margin-left: 4px;
    }

    .second-icon path {
      fill: $andes-blue-500;
      stroke: none !important;
    }
  }
}

.sc-reco-container-fallback {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  background-color: $andes-gray-040-solid;
  padding: 20px 0;

  &-img {
    width: 110px;
    height: 88px;
  }

  &-subtitle {
    width: 300px;
    text-align: center;
    font-weight: $font-weight-semibold !important;
    padding-bottom: 8px;
  }
}

.modules .sc-reco-container-content {
  position: relative;

  .sc-reco-container-cards {
    width: 100%;
    display: block;
  }

  .tag {
    margin-right: 8px;
  }

  .sc-reco-container-tag-title {
    display: flex;
    width: calc(100% - 130px);
  }

  .cta-button {
    position: absolute;
    margin-top: 6px;
    top: 0;
    right: 0;
    color: $andes-blue-500;

    .icon-custom {
      margin-left: 0;
    }

    svg > path {
      fill: $andes-blue-500;
      stroke: $andes-blue-500 !important;
    }
  }

  .cta-button:hover {
    background: none;

    svg > path {
      fill: $andes-blue-500;
      stroke: $andes-blue-500 !important;
    }
  }
}

.sc-reco-container-tag-title {
  align-items: center;

  .andes-typography--type-title {
    margin: 0;
  }
}

@media (min-width: $desktop-s) and (max-width: $desktop-m) {
  .sc-reco-container {
    max-width: 900px;
    margin: auto;
  }

  .modules .sc-reco-container-content {
    .sc-reco-container-tag-title {
      margin-left: 8px;
    }
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (max-width: $mobile-xl) {
  .modules .sc-reco-container-content {
    .sc-reco-container-title {
      padding: 0;
    }

    .sc-reco-container-tag-title {
      display: block;
      margin-left: 0;
    }

    .cta-button {
      margin-left: 20px;
      position: initial;
    }
  }
}
