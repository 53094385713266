// Estilos para safari
@media screen and (width <= 1024px) {
  .header-new {
    .header-container-new {
      .nav-logo {
        order: -1;
      }

      .nav-container {
        order: -2;
      }

      .search-form {
        width: 50%;
        margin-left: auto;
        order: 3;
      }

      .user-search-container-login-not {
        display: none;
      }
    }
  }
}
