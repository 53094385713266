.andes-button.cta-button {
  border-radius: 50px;
  min-width: 150px;
  padding: 12px 0;
  flex-shrink: 0;

  .main-icon {
    background-color: $andes-white;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 6px;
    margin-right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      margin: auto;
    }

    &.icon-custom > svg path {
      stroke: $andes-blue-500 !important;
    }
  }

  .second-icon {
    margin-left: 12px;
  }

  &[class*='success']:disabled {
    background-color: $andes-green-500;

    .andes-button__text {
      color: $andes-white;
    }

    .main-icon {
      svg path {
        fill: $andes-green-500;
        stroke: none !important;
      }
    }
  }

  .andes-button__text {
    white-space: nowrap;
    overflow: unset;
  }

  &.cta-btn-main-icon {
    padding: 12px 24px;
    height: 58px;
  }
}

.cta-button.outline {
  border-radius: 24px;
  border: 1px solid $andes-blue-500;
  background-color: transparent;
  color: $andes-blue-500;
  height: 34px;
  padding: 8px 20px 8px 10px;

  .main-icon {
    height: 18px;
    width: 18px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-right: 2px;

    svg {
      margin: auto;
    }

    &.icon-custom > svg path {
      fill: $andes-blue-500 !important;
      stroke: none !important;
    }
  }

  .andes-button__text {
    font-size: $font-size-14;
    font-weight: 600;
    line-height: 18px;
  }
}

.cta-button.outline:hover {
  background-color: $andes-blue-500;
  color: $andes-white;

  .main-icon,
  .second-icon {
    svg path {
      fill: $andes-white !important;
      stroke: none !important;
    }
  }
}

.cta-button.outline:active {
  border: 1px solid $andes-blue-600;
  background-color: $andes-blue-600;
  color: $andes-white;

  .main-icon {
    svg path {
      fill: $andes-white !important;
      stroke: none !important;
    }
  }
}

.cta-button.transparent {
  color: $andes-gray-550;
  border: none;
  background-color: transparent;
  height: auto;
  padding: 0;
  min-width: auto;
  min-height: auto;

  .cta-btn-main-icon {
    padding: 0;
    height: inherit;
  }

  &:active {
    border: none;
    background-color: initial;
  }

  svg path {
    stroke: #7e7e7e !important;
  }

  .andes-button__text {
    font-size: $font-size-14;
    font-weight: 600;
    line-height: 18px;
  }

  .main-icon {
    width: initial;
    height: initial;
    padding: 0;
  }

  .main-icon svg {
    width: 13px;
  }
}

.cta-button.transparent.result {
  color: $andes-blue-500;

  svg path {
    stroke: $andes-blue-500 !important;
  }
}
