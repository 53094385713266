@media (max-width: $desktop-m) {
  .sc-card-content {
    width: 280px;

    &-container.andes-card__content {
      width: 280px;
    }

    &-image {
      width: 280px;
    }

    &-image-crop-secondary {
      width: 280px;
    }

    &-title-subtitle-title {
      width: 248px;
    }
  }
}
