// sass-lint:disable no-qualifying-elements no-misspelled-properties
.sc-carrousel .sc-carrousel-wrapper div[class^='sc-carrousel-cards'] {
  padding: 0 20px 20px;
  height: 100%;
  display: inline-flex;
  width: 100%;
  overflow: visible;
  scroll-behavior: smooth;
  scrollbar-width: 0;

  &::-webkit-scrollbar {
    display: none;
  }
}

.sc-carrousel button[class^='sc-carrousel-btns'] {
  display: none;
}
