// sass-lint:disable clean-import-paths
// sass-lint:disable class-name-format
@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

.note-html-login {
  display: flex;
  align-items: center;
  border-radius: 44px;
  border: solid 1px $andes-gray-100;

  .chevron-icon-container {
    display: flex;
  }

  .note-html-login-warranty-icon,
  .note-html-login-chevron-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .note-html-login-warranty-icon {
    border: solid 1px $andes-gray-100;
    height: 48px;
    width: 48px;
  }

  .push-login-title-container {
    padding-inline: 0;

    p {
      font-weight: 600;
    }
  }

  .Rocket40 {
    margin-right: 1em;

    p {
      font-weight: 400;
    }
  }

  .note-html-login-rocket-icon {
    height: 40px;
  }

  .note-html-login-chevron-icon {
    border: solid 1px $andes-blue-500;
    height: 32px;
    width: 32px;

    > svg path {
      fill: $andes-blue-500;
    }
  }

  @media (width <= 1200px) {
    max-width: 380px;
  }
}
