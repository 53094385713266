@media (width <= 1025px) {
  @import './index.mobile';
}

@media (width > 1025px) {
  @import './index.desktop';
}

.header-container-new {
  .nav-logo-label-not {
    font-weight: 600;
    color: $andes-black;
  }
}

.header-new.header-mobile .header-container-new {
  a.nav-logo {
    order: 0;
  }
  
  .nav-mobile-container .menu {
    .menu-container {
      display: flex;
      align-items: center;
      justify-content: center;
      background: $andes-white;
      box-shadow: 0 -4px 16px $box-shadow;
      padding: 16px;
      width: 100%;
  
      .login-btn {
        border-radius: 20px;
        padding: 17px 30px;
      }
  
      .user-icon-text {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: #38bb9c;
  
        p {
          font-size: $font-size-18;
          line-height: 35px;
          font-weight: 700;
          color: $andes-white;
        }
      }
  
      .user-container-icon {
        display: flex;
  
        &.borderImage {
          border: 1.2px solid $andes-gray-100;
        }
  
        > svg {
          width: 48px;
          height: 48px;
        }
      }
  
      .search-form:first-of-type {
        display: flex;
        width: 100%;
      }
  
      .andes-form-control--search-box {
        width: 100%;
        border-radius: 0;
        align-items: center;
        flex-direction: row-reverse;
  
        .andes-form-control__close-icon {
          display: none;
        }
  
        label {
          display: block;
        }
  
        .andes-form-control__search-icon {
          margin-top: 0;
        }
      }
  
      .andes-form-control--search-box:hover {
        box-shadow: none;
      }
    }
  
    .menu-container.login {
      justify-content: flex-start;
    }
  
    .hamburger-btn {
      position: relative;
      width: 21px;
      height: 16px;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
    }
  
    .hamburger-top-bread,
    .hamburger-patty,
    .hamburger-bottom-bread {
      position: absolute;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $andes-black;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
    }
  
    .hamburger-top-bread {
      margin-top: 0;
      transform-origin: left center;
    }
  
    .hamburger-patty {
      margin-top: 6px;
      transform-origin: left center;
    }
  
    .hamburger-bottom-bread {
      margin-top: 12px;
      transform-origin: left center;
    }
  
    .hamburger-btn.open .hamburger-top-bread {
      top: -2px;
      left: 3px;
      transform: rotate(45deg);
    }
  
    .hamburger-btn.open .hamburger-patty {
      width: 0%;
      opacity: 0;
    }
  
    .hamburger-btn.open .hamburger-bottom-bread {
      top: 1px;
      left: 3px;
      transform: rotate(-45deg);
    }
  }

  .search-form { 
    margin-right: 118px;
  }
}

@media (width > 1025px) {
  .header-new.header-mobile .header-container-new {
    .nav-logo {
      margin-right: 16px;

      svg {
        margin: 0 12px 0 21px;
      }
    }

    .nav-container.nav-mobile-container {
      margin-left: 118px;
    }

    .search-form {
      margin-left: auto;
    }
  }
}


.header-new .login-btn .andes-button__content .andes-button__text {
  color: $andes-white;
}

.header-new.header-mobile .nav-container.nav-mobile-container .sc-menu-mobile {
  box-shadow: inset 0 -4px 16px $box-shadow;
  top: 56px;
}

@media (max-width: $tablet-xl) {
  .header-new.header-mobile
    .nav-container.nav-mobile-container
    .sc-menu-mobile {
    top: 64px;
  }
}

@media (min-width: $desktop-s) and (orientation: landscape) {
  .header-new.header-mobile
    .nav-container.nav-mobile-container
    .sc-menu-mobile {
    box-shadow: inset 0 -4px 16px $box-shadow;
    top: 56px;
    left: 0;
    right: inherit;
    bottom: 0;

    &-content {
      width: 300px;
      background-color: $andes-white;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 0;
      justify-content: space-between;
      box-shadow: inset 0 4px 16px $andes-gray-100;

      .sc-navigation-mobile {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;

        &-header {
          display: flex;
          flex-direction: column;
          padding: 0 24px;
        }

        &-header > a {
          padding-block: 30px;
          font-size: $font-size-14;
        }

        &-body {
          display: flex;
          flex-direction: column;
          width: 100%;
          gap: 30px;
          padding: 0 24px;
          border-top: 1px solid $andes-gray-070-solid;
        }

        &-body > span {
          font-size: $font-size-12;
          color: $andes-gray-550;
          padding-top: 20px;
        }

        &-items {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          width: 100%;
        }
      }
    }

    .sc-user-profile {
      display: flex;
      gap: 16px;
      align-items: center;

      .sc-data-profile {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}
