// sass-lint:disable no-qualifying-elements no-misspelled-properties
.sc-carrousel::-webkit-scrollbar {
  display: none;
}

.sc-carrousel {
  width: 100%;
  overflow-x: visible;
  position: relative;
  margin-top: 20px;
  transition: overflow 0.5s ease-in-out;

  .icon-custom {
    line-height: 6px;
    text-align: center;

    circle {
      cursor: pointer;
    }
  }

  .sc-carrousel-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;

    div[class^='sc-carrousel-cards'] {
      height: 100%;
      display: inline-flex;
      padding-bottom: 20px;

      // transition: overflow 0.2 ease-in;
    }
  }
}

.sc-carrousel button[class^='sc-carrousel-btns'] {
  position: absolute;
  top: calc(50% - 32px);
  width: 32px;
  height: 32px;
  background: $andes-white;
  box-shadow: 0 4px 8px 0 $andes-gray-100;
  border-radius: 16px;
  z-index: 20;
  border: none;
  padding: 7px 8px 9px;
  cursor: pointer;

  &[class$='disabled'] {
    > div svg path {
      fill: $andes-gray-070-solid;
    }
  }

  &[class*='-right'] {
    right: -16px;
  }

  &[class*='-left'] {
    left: -16px;
  }

  > div {
    width: 16px;
    height: 16px;

    svg path {
      fill: $andes-blue-500;
    }
  }

  &:hover {
    box-shadow: 0 8px 16px 0 $andes-gray-100;

    &[class$='disabled'] {
      box-shadow: none;
    }
  }
  @media (max-width: $tablet-xl) {
    &[class*='-right'] {
      right: 0;
    }

    &[class*='-left'] {
      left: 0;
    }
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}
