@import '~@andes/snackbar/index';

.seller-coach-anchor {
  display: block;
  position: relative;
  top: -55px;
  visibility: hidden;

  & + .seller-coach .seller-coach-module-title--desktop {
    margin-bottom: 35px;
  }
}

.seller-coach-anchor.seller-coach {
  &-without-tags + .seller-coach {
    & > span {
      display: none;
    }
  }

  &-with-tags + .seller-coach {
    .seller-coach-module-title--desktop {
      margin-bottom: 15px;
    }

    & > span .seller-coach-controls {
      position: relative;

      .seller-coach-controls__bookmark {
        display: none;
      }

      .seller-coach-group-tags-slider__mask {
        mask: linear-gradient(
          90deg,
          rgb(255 255 255) 65%,
          rgb(245 245 245 / 0%) 86%
        );
      }

      .seller-coach-group-tags-slider__tags-container {
        .seller-coach-group-tags-slider__tag {
          position: relative;
          padding: 10px 0 10px 24px;
          margin-right: 48px;
          border: none;
          background: none;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          width: max-content;

          &::before {
            background: url("https://static.mlstatic.com/org-img/homesnw/img/flags@2x.png?v=4.0")
              no-repeat 0 0 transparent;
            background-size: 24px;
            content: ' ';
            display: block;
            width: 16px;
            height: 16px;
            left: 0;
            position: absolute;
            top: 12px;
            border-radius: 50%;
          }

          &.MLU::before,
          &.MLU_FULL::before {
            background-position: -2px -189px;
          }

          &.MLC::before,
          &.MLC_FULL::before {
            background-position: 0 -69px;
          }

          &.MCO::before,
          &.MCO_FULL::before {
            background-position: -2px -35px;
          }

          &.MLM::before,
          &.MLM_FULL::before {
            background-position: -4px -120px;
          }

          &.MLA::before,
          &.MLA_FULL::before {
            background-position: -4px 0;
          }

          &.MLB::before,
          &.MLB_FULL::before {
            background-position: -4px -17.5px;
          }

          &.MLE::before,
          &.MLE_FULL::before {
            background-position: -4px -103.5px;
          }

          &.MLP::before,
          &.MLP_FULL::before {
            background-position: -4.1px -154.6px;
          }

          &.MLV::before,
          &.MLV_FULL::before {
            background-position: -4px -206px;
          }
        }
      }
    }
  }
}

.seller-coach-anchor.tag-modifier-4
  + .seller-coach-desktop
  .seller-coach-group-tags-slider__tags-container {
  min-width: 550px;
}

.seller-coach-anchor.seller-coach {
  &-with-tags + .seller-coach .tags-mobile-controls {
    border-bottom: $andes-gray-100 1px solid;
    margin-bottom: 20px;
    mask: linear-gradient(90deg, #000 85%, #fff0 100%);
    padding-bottom: 10px;
    overflow: scroll;
    -ms-overflow-style: none;

    /* IE and Edge */
    scrollbar-width: none;

    /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    .tag-mobile--selected > span {
      display: none;
    }

    .tag-mobile--selected::after {
      background-color: $andes-blue-500;
      background-size: 24px;
      content: ' ';
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 2px 2px 0 0;
      left: 0;
      position: absolute;
      bottom: -10px;
    }

    .tag-mobile {
      background: none;
      border: none;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      position: relative;
      margin-right: 32px;
      padding: 10px 0 10px 24px;
      width: max-content;

      &::before {
        background: url("https://static.mlstatic.com/org-img/homesnw/img/flags@2x.png?v=4.0")
          no-repeat 0 0 transparent;
        background-size: 24px;
        content: ' ';
        display: block;
        width: 16px;
        height: 16px;
        left: 0;
        position: absolute;
        top: 12px;
        border-radius: 50%;
      }

      &.MLU::before,
      &.MLU_FULL::before {
        background-position: -2px -189px;
      }

      &.MLC::before,
      &.MLC_FULL::before {
        background-position: 0 -69px;
      }

      &.MCO::before,
      &.MCO_FULL::before {
        background-position: -2px -35px;
      }

      &.MLM::before,
      &.MLM_FULL::before {
        background-position: -4px -120px;
      }

      &.MLA::before,
      &.MLA_FULL::before {
        background-position: -4px 0;
      }

      &.MLB::before,
      &.MLB_FULL::before {
        background-position: -4px -17.5px;
      }

      &.MLE::before,
      &.MLE_FULL::before {
        background-position: -4px -103.5px;
      }

      &.MLP::before,
      &.MLP_FULL::before {
        background-position: -4.1px -154.6px;
      }

      &.MLV::before,
      &.MLV_FULL::before {
        background-position: -4px -206px;
      }
    }
  }
}
