// sass-lint:disable clean-import-paths

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

.fallback-seller-coach {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $andes-gray-040-solid;

  .fallback-seller-coach-img {
    width: 98px;
    height: 71px;
    margin-bottom: 1.5em;
  }

  .fallback-seller-coach-title {
    margin-bottom: 0.2em;
  }

  .fallback-seller-coach-subtitle {
    text-align: center;
    margin-bottom: 1.5em;
  }

  .fallback-seller-coach-link {
    color: $andes-blue-500;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
}
