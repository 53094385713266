// sass-lint:disable clean-import-paths

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

.andes-button.btn-action-feedback {
  background: $andes-gray-040-solid;
  border-radius: 40px;
  margin: 10px;
  width: 2px;
  padding: 0 22px;
}

.andes-button.btn-action-feedback:focus,
.andes-button.btn-action-feedback:visited {
  background: $andes-gray-040-solid;
}

.andes-button.btn-action-feedback:hover,
.andes-button.btn-action-feedback:active {
  background: $andes-gray-100-solid !important;
}

.color-green {
  background: $andes-green-500 !important;
}

.color-green:hover,
.color-green:active {
  background: $andes-green-600 !important;
}

.color-red {
  background: $andes-red-500 !important;
}

.color-red:hover,
.color-red:active {
  background: $andes-red-600 !important;
}

.title-reason {
  margin: 33px;
}

.container-feedback {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-send-feedback {
  height: 44px;
  width: 100%;
  border-radius: 22px;
}

.text-area-reason {
  width: 95%;
  height: 130px;
  margin: 10px;
  padding: 8px 12px;
  border-radius: 6px;
  border: solid 1px $box-shadow;
  outline: none;
  resize: none;
}

textarea,
textarea:focus {
  overflow: hidden;
  box-shadow: none;
}
