// sass-lint:disable class-name-format
// sass-lint:disable zero-unit
// sass-lint:disable no-color-literals
.note-html-login {
  height: 88px;
  width: 384px;

  .warranty-icon-container {
    padding-left: 24px;
  }

  .Warranty30 {
    p {
      font-size: $font-size-16;
    }
  }

  .chevron-icon-container {
    padding-right: 24px;
  }

  .rocket-icon-container {
    padding-left: 24px;
  }

  .Rocket40 {
    p {
      font-size: $font-size-16;
    }
  }
}

.container-Warranty30 {
  box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
}

.container-Rocket40 {
  position: sticky;
  top: 6rem;
  height: 80px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 12%);
}
