.push-login-rec {
  width: 100%;
  max-width: 35rem;
  margin: auto;
  background-color: $andes-gray-040-solid;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  padding: 33px 48px 33px 173px;
  margin-bottom: 24px;
  position: relative;

  .push-login-rec-text {
    font-weight: 600;
    margin-bottom: 24px !important;
  }

  .push-login-rec-img {
    position: absolute;
    top: 49px;
    left: 25px;
  }

  .andes-button.cta-button {
    height: 44px;
    width: 280px;
    border-radius: 22px;
  }
}
