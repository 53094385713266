.header-new .menu {
  margin: auto 0;

  .menu-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right: 4.75em;
  }

  .hamburguer-btn {
    display: none;
  }

  .menu-item {
    position: relative;
    display: flex;
    margin: auto;
    font-weight: 600;
    color: $andes-black;
    padding-right: 1.25em;
  }

  .nav-container-menu {
    position: absolute;
    right: 0;
    top: 0.15em;
    margin-top: 0.1em;
  }

  .nav-logo-label-not:visited {
    color: $andes-black;
  }
}

.header .menu {
  margin: auto 0;

  .menu-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-right: 4.75em;
  }

  .hamburguer-btn {
    display: none;
  }

  .menu-item {
    position: relative;
    display: flex;
    margin: auto;
    font-weight: 600;
    color: $andes-black;
    padding-right: 1.25em;
  }

  .nav-container-menu {
    position: absolute;
    right: 0;
    top: 0.15em;
    margin-top: 0.1em;
  }

  .mobile-only.mobile-only {
    display: none;
  }
}

.header-new.header-mobile .nav-container.nav-mobile-container .sc-menu-mobile {
  box-shadow: 0 -4px 16px $box-shadow;
  top: 64px;
  left: 0;
  right: inherit;
  bottom: 0;

  &-content {
    width: 300px;
    background-color: $andes-white;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
    justify-content: space-between;
    box-shadow: inset 0 4px 16px $andes-gray-100;

    .sc-navigation-mobile {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      height: 100%;

      &-header {
        display: flex;
        flex-direction: column;
        padding: 0 24px;
      }

      &-header > a {
        padding-block: 30px;
        font-size: $font-size-14;
      }

      &-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 30px;
        padding: 0 24px;
        border-top: 1px solid $andes-gray-070-solid;
      }

      &-body > span {
        font-size: $font-size-12;
        color: $andes-gray-550;
        padding-top: 20px;
      }

      &-items {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        width: 100%;
      }
    }
  }

  .sc-user-profile {
    display: flex;
    gap: 16px;
    align-items: center;

    .sc-data-profile {
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }
}
