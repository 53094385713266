.container-message-feedback {
  padding: 0.5em;
  max-width: 25em;
  margin: 0 auto;
  text-align: center;

  .icon-custom {
    margin: 15px 0;
  }
}
