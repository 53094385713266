// sass-lint:disable no-qualifying-elements

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

.cta-new-note-container {
  display: flex;
  justify-content: center;
  padding: 1.5em 1em;

  .cta-new-note-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cta-new-note-title {
      font-weight: 600;
      font-size: $font-size-18;
      line-height: 22px;
      text-align: center;
      margin-bottom: 1em;
    }

    .cta-new-note-button {
      border-radius: 32px;
      border: none;
      background: $andes-blue-500;

      .cta-new-note-button-text {
        padding: 0.7em 1.7em;
        color: $andes-white;
        text-transform: none;
        display: block;
      }

      span.andes-button__text {
        text-transform: lowercase;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
      }

      span.andes-button__text::first-letter {
        text-transform: uppercase;
      }
    }
  }
}
