// sass-lint:disable no-misspelled-properties
.footer {
  & .footer-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 0;
  }

  & .anchor-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.5em;
    margin-top: 1.5em;
    padding-top: 2em;
    border-top: 1px solid $andes-bg-primary;
    font-size: 13px;
    width: 100%;
  }
}
