.nav-logo-cbt {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('https://http2.mlstatic.com/frontend-assets/sc-seller-university-frontend/ML_color_izquierda.png');
  overflow: hidden;
  text-indent: -999px;
  height: 34px;
  width: 134px;
  display: flex;
  position: static;
  left: 0;
  top: 0;
}
