// sass-lint:disable no-color-literals variable-name-format mixin-name-format
@import '../Menu';
@import '../../components/SuTitle';

@mixin search($size: $desktop-s, $sizeSearch: 400px, $marginRightUser: 40px) {
  @media screen and (min-width: $size) {
    .search-form {
      min-width: calc(#{$sizeSearch} - 5px);
      max-width: $sizeSearch;
      order: 2;
    }

    .user-search-container-login {
      margin-left: 0;
      margin-right: $marginRightUser;
      order: 5;
      font-size: $font-size-14;
      cursor: pointer;
    }
  }
}

@mixin searchNot(
  $size: $desktop-s,
  $sizeSearch: 330px,
  $marginRightButton: 61px
) {
  @media screen and (min-width: $size) {
    .search-form-not {
      min-width: calc(#{$sizeSearch} - 5px);
      max-width: $sizeSearch;
      order: 2;
    }

    .user-search-container-login-not {
      margin-left: 0;
      margin-right: $marginRightButton;
      order: 5;
      font-size: $font-size-14;
      cursor: pointer;
    }
  }
}

@mixin resp($size: $desktop-s, $marginSvg, $marginSpan) {
  @media screen and (min-width: $size) {
    .header-container-new {
      min-width: $size;
    }

    .nav-logo-label {
      margin-left: $marginSpan;
      line-height: 20px;
      font-weight: $font-weight-regular;
      color: $andes-black;
      order: 3;
      font-size: $font-size-14;
      white-space: nowrap;
    }

    .nav-logo {
      svg {
        margin: $marginSvg;
        width: 36px;
        order: 1;
      }
    }

    .nav-logo-cbt {
      margin: $marginSvg;
      margin-right: 70px;
      order: 1;
    }
  }
}

.header-new {
  @include resp($tablet-s + 1, 0 12px 0 20px, 25px);
  @include resp($desktop-s, 0 12px 0 61px, 25px);
  @include resp($desktop-m, 0 12px 0 75px, 72px);
  @include resp($desktop-l, 0 12px 0 118px, 71px);
  @include resp($desktop-xxl, 0 12px 0 395px, 71px);

  height: 3.5em;
  width: 100%;
  box-shadow: 0 1px 2px $box-shadow;
  display: flex;
  font-size: $font-size-16;
  position: fixed;
  background: $andes-white;
  z-index: 99;
  align-items: center;
  justify-content: space-evenly;

  .header-container-new {
    height: 2.5em;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    max-width: 1920px;
  }

  .user-search-container-div {
    background: $andes-gray-250;
    width: 1px;
    height: 12px;
    order: 4;
    margin: 0 20px;
  }

  .header-container-isolated {
    justify-content: space-between;
  }

  .nav-container {
    flex-direction: row-reverse;
    padding: 4px;
    justify-content: space-between;
  }

  .nav-logo {
    display: flex;
    justify-content: space-between;

    span {
      color: $andes-black;
      font-size: $font-size-16;
      font-weight: bolder;
      line-height: 40px;
      white-space: nowrap;
    }

    &-title {
      display: contents;
      max-width: 220px;

      span {
        order: 1;
        margin-right: 20px;

        @media screen and (min-width: $desktop-m) {
          margin-right: 32px;
        }
      }

      .nav-logo-cbt {
        margin-right: 70px;
      }
    }

    &-label {
      font-size: $font-size-14;
    }

    svg {
      width: 60px;
      height: 40px;
    }

    @media (max-width: ($desktop-s - 1px)) {
      span {
        display: none;
      }

      .nav-logo-cbt {
        display: block !important;
      }
    }
  }

  .search-btn-menu {
    margin: auto 0.1rem;
    height: 20px;
  }

  .user-search-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 3px 0;
    width: 115px;

    .search-btn-header {
      display: none;
    }
  }

  .andes-form-control--search-box {
    align-items: center;

    .andes-form-control__search-icon {
      margin-top: 0;
    }
  }

  .andes-button--small svg {
    display: block;
  }

  .andes-button__text {
    color: $andes-gray-900;
    font-weight: 400;
    font-size: $font-size-14;
  }

  .andes-button--transparent path,
  .andes-button--transparent:link path,
  .andes-button--transparent:visited path,
  .andes-button--transparent:focus path {
    stroke: transparent !important;
  }

  .andes-button--transparent:focus {
    background-color: transparent;
  }

  .user-icon {
    padding-right: 1em;
  }

  .search-box {
    width: 220px;
  }

  .user-search-container-login {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .user-container-icon {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    background-color: $andes-gray-100;
    place-items: center;
    margin-right: 6px;

    &.borderImage {
      border: 1.2px solid $andes-gray-100;
    }

    &.nickname {
      text-align: center;
      align-items: center;
      display: flex;
      border-radius: 100%;
      background-color: #38bb9c;
      width: 32px;
      height: 32px;
      color: $andes-white;
      font-size: $font-size-14;
    }
  }

  .user-search-login-btn {
    font-weight: 600;
    text-align: center;
    border-radius: 17px;
    font-size: 0.875rem;
    padding: 6px;
    height: 100%;
    width: 100%;
  }

  .andes-button svg {
    max-width: none;
    max-height: none;
  }

  .logout-button {
    position: absolute;
    top: 3em;
    height: 2em;
    width: auto;
    background-color: $andes-white;
    border: solid 1px $andes-gray-100;

    .andes-button__content {
      color: $andes-gray-900;
      font-weight: 400;
      font-size: $font-size-14;
    }
  }
}

.header-container-new {
  @include search($desktop-s, 330px, 61px);
  @include search($desktop-m, 500px, 75px);
  @include search($desktop-l, 500px, 118px);
  @include search($desktop-xxl, 500px, 395px);

  @include searchNot($desktop-s + 1, 330px, 61px);
  @include searchNot($desktop-m, 500px, 75px);
  @include searchNot($desktop-l, 500px, 118px);
  @include searchNot($desktop-xxl, 500px, 395px);

  .user-search-container-login-not-button {
    font-size: $font-size-14;
    border-radius: 20px;
    height: 34px;
    width: 100%;

    .andes-button__content {
      width: max-content;
    }
  }
}

.header {
  height: 3.5em;
  width: 100%;
  box-shadow: 0 1px 2px $box-shadow;
  display: flex;
  justify-content: space-between;
  padding: 0 2.5rem;
  font-size: 16px;
  position: fixed;
  background: $andes-white;
  z-index: 99;

  .header-container {
    margin: auto;
    height: 2.5em;
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .header-container-isolated {
    justify-content: space-between;
  }

  .nav-container {
    display: none;
    flex-direction: row-reverse;
    padding: 4px;
    justify-content: space-between;
  }

  .nav-logo {
    max-width: 280px;
    display: flex;
    justify-content: space-between;

    svg {
      width: 60px;
      height: 40px;
      margin-right: 12px;
    }

    span {
      color: #2d3277;
      font-size: 19px;
      font-weight: bolder;
      line-height: 40px;
      white-space: nowrap;
    }

    &-title {
      display: flex;
      justify-content: center;
    }

    @media (width <=1200px) {
      span {
        display: none;
      }

      .nav-logo-cbt {
        display: block;
      }

      svg {
        margin-right: 40px;
      }
    }
  }

  .search-btn-menu {
    margin: auto 0.1rem;
    height: 20px;
  }

  .user-search-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding: 3px 0;
    width: 115px;

    .search-btn-header {
      display: none;
    }
  }

  .user-search-container-login-not {
    order: 5;
  }

  .andes-form-control--search-box {
    margin-right: 22em;
    max-width: 450px;
    border-radius: 0;
    align-items: center;
    flex-direction: row-reverse;

    .andes-form-control__close-icon {
      display: none;
    }

    .andes-form-control__search-icon {
      margin-top: 0;
    }
  }

  .andes-form-control--search-box:hover {
    box-shadow: none;
  }

  .andes-button--small svg {
    display: block;
  }

  .andes-button__text {
    color: $andes-gray-900;
    font-weight: 400;
    font-size: 14px;
  }

  .andes-button--transparent path,
  .andes-button--transparent:link path,
  .andes-button--transparent:visited path,
  .andes-button--transparent:focus path {
    stroke: transparent !important;
  }

  .andes-button--transparent:focus {
    background-color: transparent;
  }

  .user-icon {
    padding-right: 1em;
  }

  .search-box {
    width: 220px;
  }

  .user-search-container-login {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 1em;
  }

  .search-box-extended {
    max-width: 450px;
  }

  .user-search-container-login-not-button {
    background: none;
    color: $andes-gray-900;
    font-size: 14px;
    font-weight: 500 !important;

    svg {
      fill: $andes-gray-900;
    }

    svg path {
      stroke: none !important;
    }

    .andes-button {
      width: max-content;
    }
  }

  .user-container-icon {
    display: none;
  }

  .nav-logo-label {
    display: none;
  }

  .nav-logo-cbt {
    display: block;
  }

  .user-container-icon-old {
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 36px;
  }

  .user-search-login-btn {
    font-weight: 600;
    text-align: center;
    border-radius: 17px;
    font-size: 0.875rem;
    padding: 6px;
    height: 100%;
    width: 100%;
  }

  .andes-button svg {
    max-width: none;
    max-height: none;
  }

  .logout-button {
    position: absolute;
    top: 3em;
    height: 2em;
    width: 7em;
    background-color: $andes-white;
    border: solid 1px $andes-gray-100;

    .andes-button__content {
      color: $andes-gray-900;
      font-weight: 400;
      font-size: 14px;
    }
  }

  // ESTO SE ELIMINA CON LA NAVEGACION NUEVA
  .user-search-container {
    width: max-content;
  }

  .logout-button {
    width: max-content;
  }

  // ESTO SE ELIMINA CON LA NAVEGACION NUEVA
}

.header-new {
  .icon-custom.user-icon {
    display: none;
  }

  .icon-custom.user-container-icon-old {
    display: none;
  }
}

@media (max-width: $tablet-xl) {
  .header-new .header-container-new {
    justify-content: center;
  }
}

.header-new.header-mobile {
  z-index: 201;

  .header-container-new {
    .nav-logo {
      order: 1;
    }

    .nav-container.nav-mobile-container {
      order: 0;
      display: block;
    }
  }
}

.search-form-not {
  order: 2;
}

.user-search-container-floating-menu {
  transform: translate(-80px, 13px);

  .andes-list__item-action-button {
    cursor: pointer;
  }

  .andes-list__item-primary {
    font-size: $font-size-14;
  }

  &-logout {
    .andes-list__item-primary {
      font-weight: $font-weight-bold;
    }
  }

  &-logout > span {
    display: none;
  }
}
