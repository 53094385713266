.actions-container-shared {
  position: fixed;
  right: 30px;
  bottom: 40px;
  z-index: 1;

  .andes-thumbnail.andes-thumbnail--48 {
    margin: 10px 0;
  }
}
