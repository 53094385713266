$modal-overlay-color: rgb(0 0 0 / 80%);

.sc-modal-container {
  position: fixed;
  z-index: 200;
  inset: 0;

  &__open {
    display: block;
  }

  &__close {
    display: none;
  }

  &__overlay {
    position: fixed;
    z-index: 199;
    inset: 0;
    background: $modal-overlay-color;
  }
}
