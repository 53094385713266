.fallback-seller-coach {
  height: 243px;
  border-radius: 32px;
  margin: 0 1.25em 2em;

  .fallback-seller-coach-title {
    font-size: $font-size-18;
  }

  .fallback-seller-coach-subtitle {
    font-size: $font-size-14;
  }
}
