// sass-lint:disable clean-import-paths
@media (min-width: $desktop-s) {
  @import './index.desktop';
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (orientation: landscape) {
  .share-button-container {
    position: fixed;
    right: 30px;
    bottom: 40px;
    z-index: 1;

    .andes-thumbnail.andes-thumbnail--48 {
      margin: 10px 0;
    }
  }
}
