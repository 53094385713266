.sc-reco-container {
  margin-bottom: $andes-spacing-40;

  &-title.andes-typography--type-title {
    padding: 0 20px;
    line-height: 25px;
    font-size: $font-size-20;
    font-weight: $font-weight-semibold;
    margin: 10px 0 8px;
  }

  &-subtitle.andes-typography--type-body {
    padding: 0 20px;
    font-size: $font-size-14;
    line-height: 16px;
    color: $andes-gray-550;
    font-weight: $font-weight-regular;
  }

  .cta-button.outline {
    padding: 0 16px 0 20px;
    margin-left: 20px;
    margin-top: 16px;

    .second-icon {
      margin-left: 4px;
    }
  }
}

.sc-reco-container-fallback-subtitle {
  width: 170px;
}

.modules .sc-section-container {
  &-content .sc-section-container-title.andes-typography--type-title {
    padding-left: 0;
  }

  &-cards {
    .sc-reco-container-tag-title {
      padding-left: 20px;
    }

    .cta-button {
      right: 20px;
    }
  }
}

.modules
  .sc-reco-container
  .sc-reco-container-content.with-tooltip
  .sc-reco-container-tag-title {
  display: flex;
  flex-wrap: wrap;

  .sc-reco-container-title {
    width: 100%;
    order: 3;
  }

  .tag {
    order: 1;
  }

  .sc-reco-tooltip {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: $andes-gray-040-solid;
    padding: 0 4px 1px;
    order: 2;

    &.just-icon {
      padding: 4.5px 3px 0 3.3px;
    }

    .sc-global-tooltip-button {
      border: none;
      background: none;
      width: 100%;
      height: 100%;
    }

    .sc-modal-container {
      display: none;
    }

    .sc-global-tooltip-icon {
      width: 16px;
      height: 16px;
      margin-top: -2px;

      & > svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}
