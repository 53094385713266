// sass-lint:disable class-name-format
.presentation-image {
  width: 100%;
}

.sc-html-content {
  padding: 1.25em;
  font-size: 18px;

  strong,
  h6 {
    font-weight: 600;
  }

  li {
    margin-bottom: 10px;
  }

  h1 span {
    font-size: $font-size-24;
  }

  h2 {
    margin-bottom: 0.5em;
    font-size: $font-size-24;
  }

  h2 strong,
  h3 strong {
    font-size: $font-size-24;
    font-weight: 600;
  }

  h3 {
    margin-bottom: 0.5em;
    font-size: $font-size-24;
  }

  p span,
  p strong {
    display: contents;
  }

  .text {
    &__positioned {
      margin: 2em 0;
    }

    &image {
      &__positioned_right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      &__positioned_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        p span,
        p strong {
          text-align: left;
        }
      }

      &-image-container_left__small,
      &-image-container_right__small,
      .image-container_one__xlarge {
        width: 7.5em;
      }
    }
  }

  .image-container_one__xlarge {
    padding: 1em 0;
    width: 100%;
  }

  .text-container,
  .phrase-text-container .phrase-text-bloclquote {
    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }

    h4 {
      font-size: 16px;
    }

    h5 {
      font-size: 14px;
    }

    h6 {
      font-size: 12px;
    }

    p span {
      padding: 1em 0;
      line-height: 1.375rem;
      font-weight: 400;
      text-align: left;
    }

    h2 span {
      padding: 16px 0;
      font-size: $font-size-24;
      line-height: 2.188em;
      font-weight: 600;
      text-align: left;
    }

    h3 span {
      padding: 16px 0;
      font-size: $font-size-24;
      line-height: 2.188em;
      font-weight: 600;
      text-align: left;
    }

    ol {
      padding-left: 3em;
    }

    ul {
      padding-left: 3em;
    }

    p {
      margin-bottom: 0.5rem;
    }

    a {
      color: $andes-blue-500;
    }
  }

  .html__positioned {
    width: 100%;

    > iframe {
      width: 100%;
      margin: 30px 0;
    }
  }

  .phrase__positioned {
    margin: 2em 0;
  }

  .cta__positioned,
  .button__positioned {
    display: flex;
    border-radius: 6px;
    justify-content: center;
    margin-bottom: 0.5em;

    .cta-container_one,
    .cta-container_two,
    .button__container_two {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .cta-title {
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        margin: 0;
        text-align: center;
      }

      .cta-button,
      .cta-button-main,
      .btn-button_main {
        border: 0;
        background: none;
        display: flex;
        align-items: center;

        a {
          background-color: $andes-blue-500;
          border-radius: 22px;
          margin-top: 1em;
          min-height: 3em;
          padding: 1em 1.5em;
          color: $andes-white;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 15px;
        }
      }

      .cta-button,
      .cta-button-main,
      .btn-button_main {
        a:active {
          border-color: transparent;
          background-color: $andes-blue-700;
          color: $andes-white;
        }
      }

      .cta-button-secondary,
      .btn-button_secondary {
        background-color: transparent;
        border-color: transparent;
        border-radius: 6px;
        margin-top: 1em;
        min-height: 3em;
        padding: 1em 1.5em;
        width: 100%;
      }
    }
  }
}

.sc-html-content::before {
  content: ' ';
  position: absolute;
  top: 1.25em;
  left: 1.25em;
  right: 1.25em;
  border-top: 1px solid $andes-gray-100-solid;
}
