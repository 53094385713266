.yt-video {
  padding: 0;
}

@media (width <= 700px) {
  .yt-video {
    height: 300px;

    iframe {
      height: 300px;
    }
  }
}
