// sass-lint:disable no-misspelled-properties no-qualifying-elements no-vendor-prefixes
.sc-card-content {
  border-radius: 20px;
  cursor: pointer;
  margin-top: 0 !important;
  height: 290px;
  width: 328px;
  position: relative;

  &-container.andes-card__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0 !important;
    padding: 20px 24px;
    height: 290px;
    width: 328px;
  }

  &-image-container {
    margin-top: 0 !important;
    display: flex;
    justify-content: center;
    position: relative;
    height: 145px;

    .icon-custom {
      position: absolute;
      bottom: 20px;
    }
  }

  &-image {
    position: relative;
    bottom: 20px;
    object-fit: cover;
    object-position: 100% 5%;
    min-height: 145px;
    width: 328px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &-image-module {
    position: relative;
    bottom: 20px;
    object-fit: cover;
    min-height: 145px;
    width: 280px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }

  &-image-crop-secondary {
    position: relative;
    bottom: 20px;
    object-fit: contain;
    object-position: 50% 10px;
    height: 145px;
    width: 328px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #e3eefb;
  }

  &-image.icon-video {
    filter: contrast(0.7);
  }

  &-title-subtitle-container {
    display: flex;
    width: 100%;
    text-align: left;
  }

  &-title-subtitle-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    min-height: 44px;
    width: 95%;
    height: auto;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 22px !important;
    margin-top: 0 !important;
    margin-bottom: 16px !important;
  }

  &-info {
    margin-top: 0 !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 18px;
    width: 100%;
    border-top: 1px solid $andes-gray-100;

    .icon-text {
      gap: 4px;

      > div {
        height: 20px;
        background-color: transparent !important;
      }

      .icon-custom svg {
        margin: auto;
      }

      .icon-custom svg path {
        fill: $andes-gray-550;
      }

      .icon-custom svg.sc-guide-book-icon path {
        fill: transparent;
      }

      p {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $andes-gray-550;
        margin: 0 !important;
      }
    }
  }
}

.sc-card-content-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;

  &-img {
    width: 120px;
    height: 88px;
    margin-bottom: 8px;
  }

  &-subtitle {
    width: 190px;
    text-align: center;
    padding-bottom: 8px;
  }
}

.sc-card-content.success {
  background-color: $andes-gray-040-solid;

  .sc-card-content-image,
  .sc-card-content-image-crop-secondary {
    filter: grayscale(1);
  }

  .sc-reco-card-tooltip .andes-tooltip__trigger {
    background-color: $andes-gray-040;
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}
