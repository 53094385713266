// sass-lint:disable class-name-format
@import '../../styles/common';

@import '../../components/NoteHtml';
@import '../../components/PushLoginRec';
@import '../../components/ContentsModule';
@import '../../components/ActionsSharedAndFavorite';
@import '../../components/FeedBack';
@import '../../components/FeedBackMessageView';
@import '../../components/CtaNewNotes';
@import '../../components/Banner';
@import '../../components/YoutubeVideo';
@import '../../components/Fallback';
@import '../../components/FallbackSellerCoach';
@import '../../components/IconText';
@import '../../components/NoteTitle';
@import '../../components/NoteSubtitle';
@import '../../components/ShareButton';
@import '../../components/RecoContainer';
@import '../../components/Carrousel';
@import '../../components/Card';
@import '../../components/CardContainer';
@import '../../components/Tag';
@import '../../components/CtaButton';
@import '../../components/Search';
@import '../../containers/SellerCoach';

@import '../../styles/common.desktop';

.seller-coach-desktop {
  margin-bottom: 30px;
  max-width: 600px;
  min-width: 600px;
  padding: 10px 10px 0;
}

.sc-note {
  .sc-view-note {
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 0.45fr 4.75fr 2fr 1fr;

    .sc-note_presentation_container {
      grid-column: 2 / 3;
      font-size: 1rem;
      padding: 2em 1.25em 0;
      padding-left: 4.75em;
      padding-right: 3em;
    }

    .sc-note_presentation_container .cta-button.outline {
      margin-bottom: 32px;
      min-width: unset;
      height: 34px;
      padding: 0 20px 0 16px;
    }

    .right-content-container {
      position: relative;
      padding-top: 32px;
      height: 100%;
      padding-bottom: 6rem;
      grid-row: 2 / 4;

      > div {
        position: sticky;
        top: 5rem;
      }
    }

    .sc-backing-note-container {
      grid-column: 2 / 3;
      font-size: 1rem;
      padding: 1.7em 3em 45px 4.75em;

      .sc-card-container {
        max-width: 588px;
        margin-bottom: 35px;
      }

      .sc-card-container-text {
        margin-left: 0;
      }
    }

    .backing-note-container-video {
      grid-column: 2 / 4;
      max-width: 40rem;
      width: 100%;
      margin: auto;
    }

    & > .sc-reco-container {
      grid-column: 2 / 4;
      padding-left: 4.75em;
      max-width: none;
    }
  }

  .sc-view-note.fallback {
    display: flex;
    height: calc(100vh - 64px);
  }

  .sc-view-note-video {
    position: relative;
    width: 100%;
    margin: auto;
    max-width: 1280px;
    display: grid;
    grid-template-columns: 0.2fr 2fr 1.5fr;

    .sc-note_presentation_container {
      grid-column: 2 / 4;
      font-size: 1rem;
      padding: 2.25em 1.25em 0;
      padding-left: 4.75em;
      padding-right: 3em;
    }

    .presentation-main-container ~ .right-content-container {
      grid-row: 3/4 !important;
    }
  }

  .sc-backing-note-container .sc-reco-container {
    max-width: 588px;
    border: 1px solid $andes-gray-070-solid;
    box-shadow: 0 1px 2px $box-shadow;
    border-radius: 20px;
    padding: 32px;

    .cta-button.outline {
      min-width: unset;
      padding: 0 20px 0 16px;
    }
  }
}

.sc-note_presentation_container {
  .icon-text {
    height: 1.5em;
    justify-content: start;

    .icon-custom {
      margin-right: 0.7em;
      padding-top: 5px;
    }

    .andes-typography {
      margin: 0;
      font-weight: 600;
      color: $andes-gray-550-solid;
    }
  }
}

.sc-view-note .sc-reco-container .sc-reco-container-content {
  position: relative;
  margin-bottom: 100px;
  max-width: 1000px;

  .sc-reco-container-cards {
    width: 100%;
    display: block;
  }

  .tag {
    margin-right: 8px;
  }

  .sc-reco-container-tag-title {
    display: flex;
    width: calc(100% - 130px);
  }

  .cta-button {
    position: absolute;
    margin-top: 5px;
    top: 0;
    right: 0;
    color: $andes-blue-500;

    .icon-custom {
      margin-left: 0;
    }

    svg > path {
      fill: $andes-blue-500;
      stroke: $andes-blue-500 !important;
    }
  }

  .cta-button:hover {
    background: none;

    svg > path {
      fill: $andes-blue-500;
      stroke: $andes-blue-500 !important;
    }
  }
}

@media (max-width: $desktop-m) {
  .sc-note .sc-view-note .sc-reco-container {
    padding-left: 4.75em;
    width: 100%;
    max-width: none;
  }
}

@media (max-width: $tablet-xl) {
  .sc-note {
    .sc-view-note {
      display: grid;
      grid-auto-columns: auto;
      grid-template-columns: 100%;

      .sc-note_presentation_container {
        grid-area: 1 / 1 / 2 / 2;
        padding-left: 78px;
        padding-right: 78px;
      }

      .presentation-image-container {
        grid-area: 2 / 1 / 3 / 2;
      }

      .right-content-container {
        display: none;
      }

      .sc-html-content {
        grid-column: 1;
        padding-left: 78px;
        padding-right: 78px;
      }

      .sc-backing-note-container {
        grid-area: 4 / 1 / 5 / 2;
        padding-left: 78px;
        padding-right: 78px;
      }

      & > .sc-reco-container {
        grid-column: 1;
        max-width: none;
        padding-right: 100px;
      }
    }
  }
}
