// sass-lint:disable clean-import-paths
@media (width <= 999px) {
  @import './index.mobile';
}

@media (width >= 1000px) {
  @import './index.desktop';
}

.footer {
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  background: $andes-bg-white;
  border-width: 1px 0;
  border-style: solid;
  border-color: $andes-bg-primary;
  font-size: 1.2em;

  & .footer-item {
    width: 100%;

    & p {
      font-weight: 400;
      font-size: 14px;
    }

    & p {
      color: $andes-black;
    }

    & a {
      font-size: 13px;
    }

    &.secondary {
      font-size: 1.4em;
      background: $andes-bg-secondary;
    }

    &.final {
      height: 6.75rem;
    }
  }
}

.guide-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .guide-footer-login {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $andes-gray-040-solid;
    border-width: 1px 0;
    border-style: solid;
    border-color: $andes-gray-070-solid;
    width: 100%;
    height: 52px;
    padding: 0 20px;
    text-align: center;

    > a {
      font-size: $font-size-14;
      font-weight: 400;
      line-height: 15px;
    }

    > p {
      font-size: $font-size-14;
      font-weight: 400;
      line-height: 15px;
    }
  }

  .guide-footer-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid $andes-gray-070-solid;
    width: 100%;
    height: 52px;

    > a {
      font-size: $font-size-12;
      font-weight: 400;
      line-height: 15px;
    }

    > p {
      font-size: $font-size-12;
      font-weight: 400;
      line-height: 15px;
    }
  }

  .guide-footer-item.item1 {
    height: 108px;
    align-items: flex-start;

    > a {
      margin-top: 18px;
      font-size: $font-size-12;
      font-weight: 400;
      line-height: 15px;
    }
  }
}
