// sass-lint:disable clean-import-paths
.actions-container-shared {
  grid-area: 1 / 1 / 4 / 2;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 14em;

  .andes-thumbnail-container {
    padding-left: 0.5em;
  }

  .action-shared {
    display: none;
  }
}
