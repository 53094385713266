// sass-lint:disable clean-import-paths no-qualifying-elements nesting-depth
// sass-lint:disable no-misspelled-properties
@media (max-width: $tablet-xl) {
  .contents-module {
    width: auto;
    margin: 32px 20px 0;
    background-color: $andes-white;
    position: relative;
  }
}

.right-content-container .contents-module {
  width: 384px;
  margin-bottom: 24px;

  &-completed-icon > svg {
    width: 20px;
    height: 20px;
  }
}

.contents-module {
  border: 1px solid $andes-gray-070-solid;
  box-shadow: 0 1px 2px $box-shadow;
  border-radius: 24px;
  max-width: 568px;

  div[class^='contents-module-description'] {
    padding: 24px;

    &[class$='-extend'] {
      padding: 32px 32px 20px;
    }

    .contents-module-subtitle {
      font-weight: 600;
    }

    .contents-module-title {
      font-size: 20px;
      padding-top: 8px;
    }

    .contents-module-completed {
      display: flex;
      margin-top: 16px;
      height: 20px;
      align-items: center;

      .contents-module-completed-icon {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }

      .contents-module-completed-text {
        font-weight: 600;
        font-size: 14px;
        margin: 0;
      }

      &.circle {
        .contents-module-completed-text {
          font-weight: 400;
          color: $andes-green-500;
        }
      }
    }
  }

  .contents-module-carousel {
    width: 100%;
    height: 116px;
    overflow-x: visible;
    position: relative;

    button[class^='contents-module-carousel-btns'] {
      position: absolute;
      top: 32px;
      width: 32px;
      height: 32px;
      background: $andes-white;
      box-shadow: 0 1px 2px $andes-gray-100;
      border-radius: 16px;
      z-index: 1024;
      border: none;
      padding: 7px 8px 9px;

      &[class$='disabled'] {
        > div svg path {
          fill: $andes-gray-070-solid;
        }
      }

      &[class*='-right'] {
        right: -16px;
      }

      &[class*='-left'] {
        left: -16px;
      }

      > div {
        width: 16px;
        height: 16px;

        svg path {
          fill: $andes-blue-500;
        }
      }
    }

    button[class^='contents-module-carousel-btns']:hover {
      box-shadow: 0 8px 16px 0 $andes-gray-100;

      &[class$='disabled'] {
        box-shadow: none;
      }
    }

    .contents-module-carousel-wrapper {
      width: 100%;
      height: 100%;
      overflow: hidden;

      div[class^='contents-module-carousel-cards'] {
        height: 100%;
        display: inline-flex;
        gap: 8px;
        padding: 0 32px;

        &[class$='mobile'] {
          width: 100%;
          overflow: scroll;
          scroll-snap-type: x mandatory;
          scrollbar-width: 0;
        }

        &[class$='mobile']::-webkit-scrollbar {
          display: none;
        }

        div[class^='contents-module-carousel-card'] {
          width: 260px;
          height: 96px;
          display: flex;
          background: $andes-white;
          border: 1px solid $andes-gray-070-solid;
          box-shadow: 0 6px 16px $box-shadow;
          border-radius: 12px;
          padding: 16px 20px;
          scroll-snap-align: center;

          &[class$='-completed'] {
            .module-carousel-card-id {
              border: none;
              background-color: $andes-green-500;
              padding: 1px 2px 0;
            }
          }

          .module-carousel-card-id {
            width: 20px;
            height: 20px;
            border: 1px solid $andes-gray-550;
            border-radius: 50%;
            margin-right: 8px;
            margin-top: 6px;

            p {
              font-size: 14px;
              font-weight: 600;
              line-height: 18px;
              text-align: center;
              color: $andes-gray-550;
            }
          }

          .module-carousel-card-data {
            width: 192px;

            > p {
              height: 40px;

              //white-space: wrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }

          .content-module-card-pill {
            height: 20px;
            margin-top: 8px;

            > p {
              font-size: 12px;
              padding: 6px;
            }
          }

          .content-module-card-duration {
            display: flex;
            height: 20px;
            padding-top: 10px;
            align-items: center;
          }

          .content-module-card-dispatch-icon {
            margin-right: 8px;
            width: 16px;
            height: 20px;
          }
        }
      }
    }
  }

  .contents-module-carousel-progress {
    margin: 0 32px 32px;
    display: flex;
    justify-content: center;
    position: relative;
  }

  .contents-module-carousel::-webkit-scrollbar {
    display: none;
  }

  .contents-module-fallback {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 280px;
    margin: 3.5em auto;

    .fallback-image {
      width: 110px;
      height: 88px;
      margin-bottom: 1em;
    }

    .fallback-title {
      margin-bottom: 0.5em;
    }
  }
}
