@import '../Menu';
@import '../../components/SuTitle';

.header-new {
  height: 64px;
  box-shadow: 0 1px 2px $box-shadow;
  display: flex;
  padding: 16px 20px;
  position: fixed;
  width: 100%;
  background: $andes-white;
  z-index: 201;
  top: 0;

  .header-container-new {
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: nowrap;

    .user-search-container-login {
      display: none;
    }
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    margin-right: 16px;
  }

  .nav-logo {
    width: 2.8rem;
    margin-right: 16px;

    & svg {
      width: 100%;
      height: 100%;
    }

    & span {
      display: none;
    }

    .nav-logo-cbt {
      display: block;
    }
  }

  .nav-container-menu {
    margin: auto 0.1rem;
    height: 1.5rem;
  }

  .search-btn-menu {
    display: none;
    margin: auto 0.1rem auto 20px;
    height: 20px;
  }

  .search-btn-menu-logued {
    margin: auto 0.1rem auto auto;
    height: 20px;
  }

  .user-search-container {
    display: none;
    width: 8.75rem;
    margin-left: auto;
    justify-content: right;
  }

  .search-form {
    display: flex;
    width: 55%;
  }

  .search-btn-header {
    padding: 6px 0;
  }

  .user-search-login-btn {
    width: 6.25rem;
    font-weight: 600;
    text-align: center;
    border-radius: 1rem;
    font-size: 0.875rem;
    padding: 8px;
    line-height: 1.125rem;
    height: 100%;
  }

  .user-search-login-btn-isolated {
    margin-right: 0;
  }
}

.header-container-new.sc-search-focus {
  .nav-container,
  .nav-logo {
    display: none;
  }
}

@media (width <= 374px) {
  .header-container-new {
    .nav-container {
      margin-right: 8px;
    }
  }
}

@media (min-width: $tablet-s) {
  .header-new .search-form {
    right: 0;
    position: absolute;
    margin-right: 20px;
    width: 50%;
  }
}

.header {
  height: 3em;
  box-shadow: 0 1px 2px $box-shadow;
  display: flex;
  justify-content: space-between;
  padding: 0 1.375rem;
  position: fixed;
  width: 100%;
  background: $andes-white;
  z-index: 9999;

  .header-container {
    margin: auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row-reverse nowrap;
    justify-content: space-between;

    .search-form {
      display: none;
    }

    .sc-modal-search {
      display: none;
    }

    .user-search-container-login {
      display: none;
    }
  }

  .nav-container {
    display: flex;
    justify-content: space-between;
    margin-right: 10px;
  }

  .nav-logo {
    width: 2.8rem;

    & svg {
      width: 100%;
      height: 100%;
    }

    & span {
      display: none;
    }

    .nav-logo-cbt {
      display: block;
    }
  }

  .nav-container-menu {
    margin: auto 0.1rem;
    height: 1.5rem;
  }

  .search-btn-menu {
    display: none;
    margin: auto 0.1rem auto 20px;
    height: 20px;
  }

  .search-btn-menu-logued {
    margin: auto 0.1rem auto auto;
    height: 20px;
  }

  .user-search-container {
    display: none;
    width: 8.75rem;
    margin-left: auto;
    justify-content: right;
  }

  .search-form {
    display: flex;
  }

  .search-form:last-child {
    display: none;
  }

  .search-btn-header {
    padding: 6px 0;
  }

  .user-search-login-btn {
    width: 6.25rem;
    font-weight: 600;
    text-align: center;
    border-radius: 1rem;
    font-size: 0.875rem;
    padding: 8px;
    line-height: 1.125rem;
    height: 100%;
  }

  .user-search-login-btn-isolated {
    margin-right: 0;
  }
}
