// sass-lint:disable clean-import-paths
@media (width >= 1300px) {
  .presentation-secondary {
    border-bottom-right-radius: 2rem;
  }
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}
