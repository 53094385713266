// sass-lint:disable clean-import-paths
// sass-lint:disable class-name-format
.sc-fallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 320px;
  height: 315px;

  &-img {
    width: 154px;
    height: 141px;
    margin-bottom: 1.5em;
  }

  &-title {
    margin-bottom: 0.5em;
  }

  &-subtitle {
    text-align: center;
    margin-bottom: 1.5em;
  }
}

.sc-fallback-simple-row,
.sc-fallback-expandable-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid $andes-gray-070-solid;
  border-radius: 6px;
  padding: 14px 26px 14px 15px;
  margin-block: 16px;
  width: 100%;
  margin-left: 43px;

  &-img {
    width: 57.5px;
    height: 46px;
    margin-right: 16px;
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-right: auto;
  }

  &-link {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }
}

.sc-fallback-expandable-row {
  height: 131px;
}

.sc-fallback-sub-list-expanded {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $andes-gray-070-solid;
  border-radius: 6px;
  padding-top: 37px;
  height: 279px;
  margin-left: 75px;
  margin-bottom: 19px;

  &-img {
    width: 110px;
    height: 88px;
    margin-bottom: 33px;
  }

  &-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 36px;
  }

  &-link {
    font-size: 14px;
  }
}

.sc-fallback-filter,
.sc-fallback-row-filter,
.sc-fallback-filter-values {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 68px auto;
  width: 316px;

  &-img {
    width: 110px;
    height: 88px;
    margin-bottom: 2px;
  }

  &-title {
    font-weight: $font-weight-semibold;
    font-size: $font-size-16;
    line-height: 20px;
    text-align: center;
    margin-bottom: 8px;
  }

  &-link {
    font-weight: $font-weight-semibold !important;
    font-size: $font-size-14 !important;
    line-height: 18px !important;
  }
}

.sc-fallback-row-filter {
  margin: 20px auto;
}

.sc-fallback-filter-values {
  margin: 0;
  width: 100%;
}

.sc-empty-search,
.sc-empty-search-filters {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 154px auto;
  width: 344px;

  &-img {
    width: 120px;
    height: 120px;
  }

  &-title {
    font-weight: $font-weight-semibold;
    font-size: $font-size-18;
    line-height: 30px;
    text-align: center;
    margin-bottom: 8px;
  }

  &-subtitle {
    text-align: center;
    font-size: $font-size-14 !important;
    line-height: 20px !important;
    margin-bottom: 8px;
  }

  &-link {
    font-weight: $font-weight-semibold !important;
    font-size: $font-size-14 !important;
    line-height: 18px !important;
  }

  &-button {
    border: none;
    cursor: pointer;
    background-color: $andes-white;
    color: $andes-blue-500;
    font-weight: $font-weight-semibold;
    font-size: $font-size-14;
    line-height: 18px;
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}
