// sass-lint:disable no-misspelled-properties
.footer {
  & .footer-item {
    display: flex;
    align-items: center;
    height: 4rem;
    border-bottom: none;
    max-width: 1920px;
  }

  & .anchor-container {
    display: flex;
    gap: 1.5em;
    align-items: center;
    margin-left: 2em;
    font-size: 13px;
  }

  @media (min-width: $desktop-s) {
    & .footer-item {
      max-width: 960px;
    }
  }

  @media (min-width: $desktop-m) {
    & .footer-item {
      max-width: 1200px;
    }
  }

  @media (min-width: $desktop-xl) {
    & .footer-item {
      max-width: 1290px;
    }
  }
}
