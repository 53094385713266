.yt-video {
  max-width: 1280px;
  margin: 2rem auto;
  width: 100%;
  height: 560px;
  padding: 0.5em 4.75em;
}

@media (width <= 1199px) {
  @import './index.mobile';
}
