.push-login-rec {
  background-color: $andes-gray-040-solid;
  border-radius: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 116px 20px 33px 28px;
  margin: 14px 20px 24px;
  margin-bottom: 24px;
  position: relative;

  .push-login-rec-text {
    font-weight: 600;
    text-align: center;
    margin-bottom: 16px !important;
  }

  .push-login-rec-img {
    position: absolute;
    top: 19px;
  }

  .andes-button.cta-button {
    height: 44px;
    width: 100%;
    border-radius: 22px;
  }
}
