// sass-lint:disable no-misspelled-properties
.sc-card-container {
  width: initial;
  max-width: 530px;
  display: block;

  &.flex {
    width: 100%;
    display: block;

    .sc-card-container-text {
      align-items: center;
      width: auto;
    }
  }

  .sc-card-container-content.flex {
    width: 100%;
    display: block;
  }

  &-text {
    margin-left: 0;
    text-align: center;
  }

  &-icon {
    display: inline-flex;
    width: 54px;
    height: 54px;
  }

  &-icon svg {
    width: 54px;
    height: 54px;
  }
}

.sc-card-icon-flex {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  padding: 24px 30px;

  .sc-card-container-content {
    display: none;
  }
}
