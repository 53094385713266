.sc-card-container {
  max-width: 1129px;
  margin: auto;
  align-items: center;
  border: 1px solid $andes-gray-070-solid;
  box-shadow: 0 1px 2px $box-shadow;
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 80px;

  &-content {
    margin-bottom: 20px;
  }

  .blue {
    h1 {
      color: $andes-blue-500;
    }
  }

  &-icon {
    align-items: center;
    display: flex;
    width: 64px;
    height: 64px;
  }

  &.flex {
    display: flex;

    .sc-card-container-text {
      align-items: center;
      width: 314px;
    }

    .sc-card-container-icon svg rect {
      fill: $andes-blue-500;
    }

    .sc-card-container-icon svg {
      width: 64px;
      height: 64px;
    }
  }

  .sc-card-container-content.flex {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }

  &-text {
    margin-left: 20px;
    text-align: left;
  }

  .home-subtitle {
    font-weight: $font-weight-regular;
    font-size: $font-size-14;
    color: $andes-gray-550;
  }

  .home-title {
    color: $andes-blue-500;
    padding-bottom: 8px;
  }

  .sc-card-child {
    text-align: left;
  }
}

.sc-card-icon-flex {
  height: 148px;
  border: none;
  box-shadow: none;
  display: flex;
  padding: 24px;
  margin: 0;
}

.sc-card-container-fallback {
  width: 100%;
  height: 295px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin-bottom: 45px;

  &-img {
    width: 110px;
    height: 88px;
  }

  &-subtitle {
    width: 300px;
    text-align: center;
    font-weight: $font-weight-semibold;
    padding-bottom: 8px;
  }
}

@media (min-width: $desktop-s) and (max-width: $desktop-m) {
  .sc-card-container {
    max-width: 900px;

    .sc-card-container-text {
      padding-right: 60px;
    }
  }

  .sc-home-header-container .sc-card-container {
    margin: 38px auto -150px;
  }
}

@media (min-width: $desktop-m) and (max-width: $desktop-l) {
  .sc-card-container {
    max-width: 1042px;

    .sc-card-container-text {
      padding-right: 60px;
    }
  }

  .sc-home-header-container .sc-card-container {
    margin: 38px auto -150px;
  }
}

@media (min-width: $desktop-l) {
  .sc-home-header-container .sc-card-container {
    margin: 38px auto -150px;
  }
}

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}
