@import '~@andes/common/index';

$box-shadow: rgb(0 0 0 / 12%);

//su-colors
$su-transparent: rgb(255 255 255 / 0%);

// media queries desktop
$desktop-s: 1024px;
$desktop-m: 1280px;
$desktop-l: 1366px;
$desktop-xl: 1440px;
$desktop-xxl: 1920px;

// media queries tablet
$tablet-s: 768px;
$tablet-xl: 1023px;

// media queries mobile
$mobile-s: 320px;
$mobile-m: 375px;
$mobile-xl: 767px;
