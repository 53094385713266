// sass-lint:disable clean-import-paths
// sass-lint:disable class-name-format
@import '../../components/PushLogin';

@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

.sc-html-content {
  position: relative;

  strong,
  h6 {
    font-weight: 600;
  }

  p {
    margin-bottom: 1em;
  }

  .phrase-text-bloclquote {
    border-left: 2px solid $andes-gray-070-solid;
    padding-left: 24px;
  }

  .button__positioned {
    display: flex;
    justify-content: center;
    margin-bottom: 2em;

    .button__container_one > .btn-button_main {
      background-color: $andes-blue-500;
      border: 1px solid $andes-blue-500;
      width: 320px;
      min-height: 44px;
      border-radius: 32px;

      > a {
        margin: 8px;
        display: block;
        color: $andes-white;
      }
    }
  }

  .textimage-text-container_right ul,
  .textimage-text-container_left ul {
    padding-left: 3em;
  }
}
