@media (max-width: $tablet-xl) {
  @import './index.mobile';
}

@media (min-width: $desktop-s) {
  @import './index.desktop';
}

@media (max-width: $tablet-s) {
  .user-search-container-login-not {
    display: none;
  }
}
